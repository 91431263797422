<template>
  <div>
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="10"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        :search="isEditor"
      >
        <div
          v-if="isEditor"
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <div
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            @click="addNewContent"
          >
            <feather-icon 
              icon="PlusIcon" 
              svg-classes="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Add User</span>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="display_name">Name</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="role">Role</vs-th>
          <vs-th sort-key="group">Group</vs-th>
          <vs-th sort-key="is_active">Is Active</vs-th>
          <vs-th sort-key="updated_at">Created at</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.display_name }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.email }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ getRoleLabel(tr.role) }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.group }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <vs-chip 
                  v-if="tr.is_active" 
                  :color="'success'" 
                  class="product-order-status">True</vs-chip>
                <vs-chip 
                  v-if="!tr.is_active" 
                  class="product-order-status">False</vs-chip>
              </td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ updatedAtDatetimeFormat(tr.created_at) }}</p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <router-link
                    :to="{ name: 'admin-user-edit', params: { id: tr.id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { computed, onMounted } from '@vue/composition-api'
import useDatepicker from '@/use/useDatepicker'
import useCrud from '@/use/useCrud'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'AdminUserListView',
  components: { CustomTable },
  setup(props, ctx) {
    const { root: { $store } } = ctx
    const { updatedAtDatetimeFormat } = useDatepicker()
    const storeName = 'users'
    const crudFunction = useCrud(ctx, 'admin-user')
    crudFunction.routePrefix.value = 'admin-user'

    onMounted(() => {
      $store.dispatch(`${storeName}/fetchUser`)
    })

    const isEditor = computed(() => ctx.root.$acl.check('editor'))

    const getRoleLabel = (role) => {
      switch (role) {
        case "editor": return 'admin'
        case "super_editor": return 'super_admin'
        default: return role
      }
    }


    const items = computed(() => {
      const isEditor = ctx.root.$acl.check('editor')
      const isSale = ctx.root.$acl.check('sale')
      const user = ctx.root.$store.state.AppActiveUser
      const emailUser = user.email
      
      const parsedUser = $store.state.users.users.map(item => {
        const email = get(item, 'accounts.0.email')
        const linkGroup = get(item, ['user_group_links'], [])
        const groupNames = linkGroup.map((group) => {
          return get(group, ['user_group', 'group_name'], '')
        })
        const joinName = groupNames.join(', ')

        const parseItem = {
          ...item,
          email,
          role: getRoleLabel(get(item, 'accounts.0.account_roles.0.role', 'Not set')),
          group: isEmpty(groupNames) ? 'Not set' : joinName,
          is_active: get(item, 'accounts.0.active'),
          created_at: get(item, 'accounts.0.created_at')
        }

        if (isEditor || (isSale && emailUser === email)) return parseItem
        return null
      })

      return parsedUser.filter((item) => !isEmpty(item))
    })

    const addNewContent = () => {
      ctx.root.$router.push({
        name: 'admin-user-create',
      })
    }

    const updateData = (id) => {
      ctx.root.$router.push({
        name: 'admin-user-edit',
        params: { id },
      })
    }
    
    return {
      items,
      addNewContent,
      updateData,
      updatedAtDatetimeFormat,
      getRoleLabel,
      isEditor,
      editData: crudFunction.editData,
      searchKeys: ['display_name', 'email', 'role', 'group']
    }
  },
}
</script>

<style scoped></style>
